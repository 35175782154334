import { Box, Button, Flex, Heading, HStack, Image, Stack, Text, createIcon, useColorMode, useBreakpointValue, VStack, Avatar } from '@chakra-ui/react'
import * as React from 'react'
import { useState } from 'react'
// import img from './hero-background.webp'
import img from '../../../assets/svg/faceswap.akool.svg'
import ziko from '../../../assets/svg/ziko.png'
import { ChevronRightIcon } from '@chakra-ui/icons'
import CalorieCalculator from '../../elements/calorieCalculator/CalorieCalculator'
import Navbar from '../../navbar/Navbar'
import bar from '../../elements/calorieCalculator/bar.png';
import warriorIllustr from './warriorIllustr.png'
import IconBox from '../../elements/IconBox'
import { BsInstagram } from 'react-icons/bs'


const PlayIcon = createIcon({
  displayName: 'PlayIcon',
  viewBox: '0 0 58 58',
  d: 'M28.9999 0.562988C13.3196 0.562988 0.562378 13.3202 0.562378 29.0005C0.562378 44.6808 13.3196 57.438 28.9999 57.438C44.6801 57.438 57.4374 44.6808 57.4374 29.0005C57.4374 13.3202 44.6801 0.562988 28.9999 0.562988ZM39.2223 30.272L23.5749 39.7247C23.3506 39.8591 23.0946 39.9314 22.8332 39.9342C22.5717 39.9369 22.3142 39.8701 22.0871 39.7406C21.86 39.611 21.6715 39.4234 21.5408 39.1969C21.4102 38.9705 21.3421 38.7133 21.3436 38.4519V19.5491C21.3421 19.2877 21.4102 19.0305 21.5408 18.8041C21.6715 18.5776 21.86 18.3899 22.0871 18.2604C22.3142 18.1308 22.5717 18.064 22.8332 18.0668C23.0946 18.0696 23.3506 18.1419 23.5749 18.2763L39.2223 27.729C39.4404 27.8619 39.6207 28.0486 39.7458 28.2713C39.8709 28.494 39.9366 28.7451 39.9366 29.0005C39.9366 29.2559 39.8709 29.507 39.7458 29.7297C39.6207 29.9523 39.4404 30.1391 39.2223 30.272Z',
})



const Hero = () => {

  const { colorMode } = useColorMode();


  const [showVideo, setShowVideo] = useState(false);

  const handlePlayVideo = () => {
    setShowVideo(true);
  };

  const handleCloseVideo = () => {
    setShowVideo(false);
  };

  const isDesktop = useBreakpointValue({
    md: true,
    base: false
  });

  return (
    <Stack h={'100vh'} direction={{ base: 'column', md: 'row' }} spacing={0}>
  
          <Flex position='relative'  flex={1} position={'relative'}>
          <IconBox position={'absolute'}
          pointer
          as="box"
          p={2}
          cursor="pointer"
          variant={'solid'}
          rounded={'full'}
          h={"55px"}
          w={"55px"} bottom={isDesktop ? '35px' : '-28px'} left={isDesktop ? '30px' : '43%'} 
          // Instagram-like gradient background
          bg="linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%)"
          zIndex={8}
          onClick={() => window.open('https://www.instagram.com/zakaria_ben_sghir/', '_blank')} // Open Instagram URL in a new tab
        >
          <Avatar
            bg="transparent"
            icon={<BsInstagram fontSize="1.5rem" />}
          />
            
        </IconBox>
            <Image alt={'Login Image'} objectFit={'cover'} src={colorMode === "dark" ? warriorIllustr  : warriorIllustr} />
          </Flex>
   
          <Stack 
              
              flex={1} 
              bgColor={colorMode === "dark" ? '#18191A'  : ''} 
              justifyContent="center" // Vertically center the content
              alignItems="center"     // Horizontally center the content
            >
            <CalorieCalculator />
          </Stack>
      
    </Stack>
  );
};

export default Hero;

// import { Box, Button, Flex, Heading, HStack, Image, Stack, Text, createIcon, useColorMode, useBreakpointValue } from '@chakra-ui/react'
// import * as React from 'react'
// import { useState } from 'react'
// // import img from './hero-background.webp'
// import img from '../../../assets/svg/faceswap.akool.svg'
// import ziko from '../../../assets/svg/ziko.png'
// import { ChevronRightIcon } from '@chakra-ui/icons'
// import CalorieCalculator from '../../elements/calorieCalculator/CalorieCalculator'
// import Navbar from '../../navbar/Navbar'
// import bar from '../../elements/calorieCalculator/bar.png';


// const PlayIcon = createIcon({
//   displayName: 'PlayIcon',
//   viewBox: '0 0 58 58',
//   d: 'M28.9999 0.562988C13.3196 0.562988 0.562378 13.3202 0.562378 29.0005C0.562378 44.6808 13.3196 57.438 28.9999 57.438C44.6801 57.438 57.4374 44.6808 57.4374 29.0005C57.4374 13.3202 44.6801 0.562988 28.9999 0.562988ZM39.2223 30.272L23.5749 39.7247C23.3506 39.8591 23.0946 39.9314 22.8332 39.9342C22.5717 39.9369 22.3142 39.8701 22.0871 39.7406C21.86 39.611 21.6715 39.4234 21.5408 39.1969C21.4102 38.9705 21.3421 38.7133 21.3436 38.4519V19.5491C21.3421 19.2877 21.4102 19.0305 21.5408 18.8041C21.6715 18.5776 21.86 18.3899 22.0871 18.2604C22.3142 18.1308 22.5717 18.064 22.8332 18.0668C23.0946 18.0696 23.3506 18.1419 23.5749 18.2763L39.2223 27.729C39.4404 27.8619 39.6207 28.0486 39.7458 28.2713C39.8709 28.494 39.9366 28.7451 39.9366 29.0005C39.9366 29.2559 39.8709 29.507 39.7458 29.7297C39.6207 29.9523 39.4404 30.1391 39.2223 30.272Z',
// })



// const Hero = () => {

//   const { colorMode } = useColorMode();


//   const [showVideo, setShowVideo] = useState(false);

//   const handlePlayVideo = () => {
//     setShowVideo(true);
//   };

//   const handleCloseVideo = () => {
//     setShowVideo(false);
//   };


//   return (

//     <>
//       <Stack position="relative" bgColor={'#121212'} bgImage={img} height={{ base: '', md: '100vh' }}  overflowX={'hidden'}>
//         <Navbar />
//         <Stack direction={{ base: 'column', md: 'row' }} spacing={0} mt={"3px"}>
//           <Flex flex={1} bgColor={''} justifyContent={'center'} position={{base :'', md:'absolute'}} bottom={{base :'', md:'0'}} left={{base :'', md:'24'}}>
//             <Image src={ziko}  height={{base : 'auto' , md: '660px'}} width={{ md: '809,73px'}} position={'relative'}/>
//             <Image src={bar} position={'absolute'} top={{base:'80px', md: '40px'}} left={{base:'30px', md: '-40px'}} zIndex={33} width={{base:'100px', md: '190px'}}/>

//           </Flex>
//           <CalorieCalculator />
//         </Stack>
//       </Stack>
//     </>
    
    
//   )
// }

// export default Hero
